.logo{
  width: 50px;
  height: 50px;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  background-color: #0098f4;
  display: flex;
  align-items: center;
  justify-content: center;
}
