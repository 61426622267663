.Group{
  border-radius: 6px;
  background-color: rgb(#fff, 90%);
  padding: 4px;
  display: flex;
  margin-left: 10px;
  box-shadow: 0 0 2px rgb(#000, 30%);
  li{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;
    cursor: pointer;
    pointer-events: all;
    word-break: keep-all;
    white-space: nowrap;
    h3{
      margin: 0;
      font-size: 12px;
      font-weight: 600;
      color: #444;
      margin-left: 10px;
    }
    i{
      color: #444;
    }
    &:active{
      background-color: rgb(#000, 3%);
    }
  }
  .active{
    i{
      color: #fff;
    }
    h3{
      color: #fff;
    }
    background-color: #0098f4;
    border-radius: 3px;
  }
  &:first-child{
    margin-left: 0;
  }
}
