.Tool{
  position: absolute;
  top: 10px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  pointer-events: none;
  transform: translate(-50%, 0);
  user-select: none;
}
