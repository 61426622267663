.Wrapper{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  pointer-events: none;
  user-select: none;
  li{
    position: relative;
    margin-bottom: 20px;
    pointer-events: all;
    i{
      position: relative;
      display: flex;
      width: 40px;
      height: 40px;
      background-color: rgb(#000, 70%);
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 50%;
      font-size: 15px;
      cursor: pointer;
      &.active{
        background-color: rgb(#0098f4, 70%);
        color: #fff;
      }
      input{
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }
    span{
      padding: 6px 20px;
      border-radius: 4px;
      font-size: 14px;
      color: #fff;
      display: none;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 45px;
      transform: translate(0, 6px);
      background-color: rgb(#000, 90%);
      white-space: nowrap;
      word-break: keep-all;
    }
    .msg{
      position: absolute;
      top: 0;
      left: 45px;
      z-index: 2;
      background-color: rgb(#fff, 85%);
      padding: 5px 15px;
      box-shadow: 0 2px 10px rgb(#000, 10%);
      min-width: 220px;
      display: none;
      h3{
        color: #666;
        font-size: 14px;
      }
      p{
        font-size: 12px;
        color: #999;
        line-height: 1.7;
      }
    }

    &:hover{
      span{
        display: flex;
      }
      .msg{
        display: block;
      }
    }
  }
}
