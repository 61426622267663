.side{
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 50px;
  height: 100vh;
  pointer-events: none;
  background-color: rgb(#fff, 80%);
  user-select: none;
  ul{
    width: 100%;

    li{
      position: relative;
      pointer-events: all;
      margin-bottom: 20px;
      cursor: pointer;
      i{
        width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
      }
      p{
        white-space: nowrap;
        word-break: keep-all;
        font-size: 12px;
        padding: 0 15px;
        color: #666;
        margin: 0;
      }
    }
  }
}
